import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A fascinating calculation`}</h1>
    <p>{`In a week, I spend over 70 hours on my computer and my phone(combined) on average.
I spend 3 days of a week continuously with my computer! What!! Therefore I spend
upwards of 280 hours a month. Therefore, I spend... way upwards of 3360 hours every
year with a computer. There are 8760 hours in a single year. That means that I spend
upwards of 38.4% of my year with a computer. I sleep less than this! And I sleep
more than 8 hours each day.`}</p>
    <h1>{`Quote of the week`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a6c798fe585aaebb4bc2dd338beb613b/061c7/Quote-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.49999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABhklEQVQoz31Sa0+DQBDk//8bE7G1tUJtatQ+oHDlVcBCodYaYp8fTIRx96r1Fb0w2b29ZTJze0pVVajKCqDvGz5W9SX+zKvvPcQEpSxLON0Qrj6FfRnC7yQyCgJHR49htQKYFx6EFsma257KmtBCiWHdRWSkklRhhU4nhlnzMWpMMDhzMDrn6MKo+wQPPXUMuxnJ2CcMay6s917Ob09sFPnqQMgK/W6CUX0C9zKBaMYYqAdSq0EqCYLImJBrvOeca3zuaSmGpx6ekudPQtEO0T8dw2qG9FNAKhyYpI5VGDVPYqiS4jNSTE7MWkCqLLm3zyNZK7J3hTwQ0Z6gx4RMQBbZCpMPyE5PFdJWdJcj6mUIbhJMblO41zHCuxncbox7Iz8ORilfyfJ1Al+fIbiawdNT2Bd08doUohVh3LqHf5WifPk6+t+LZ3EcyvJxicVigTzLkc0yzOdzPBDSNEVRFLKRr0Y+sT9xeDiScL/bYr/dYLtZYbNeYSfzNeXPEmX5elTxH3i9AV8Ukfr4ycywAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Quote",
            "title": "Quote",
            "src": "/static/a6c798fe585aaebb4bc2dd338beb613b/5a190/Quote-1.png",
            "srcSet": ["/static/a6c798fe585aaebb4bc2dd338beb613b/772e8/Quote-1.png 200w", "/static/a6c798fe585aaebb4bc2dd338beb613b/e17e5/Quote-1.png 400w", "/static/a6c798fe585aaebb4bc2dd338beb613b/5a190/Quote-1.png 800w", "/static/a6c798fe585aaebb4bc2dd338beb613b/c1b63/Quote-1.png 1200w", "/static/a6c798fe585aaebb4bc2dd338beb613b/061c7/Quote-1.png 1216w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Resurfaced by `}<a parentName="p" {...{
        "href": "https://readwise.io"
      }}>{`Readwise.io`}</a></p>
    <h1>{`Tweets`}</h1>
    <blockquote data-cards="hidden" className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    One tweet summary of
    <a href="https://twitter.com/RoamResearch?ref_src=twsrc%5Etfw">
      @RoamResearch
    </a>
    <br />
    <br />
    Type a sentence like:
    <br />- Had <a href="https://twitter.com/hashtag/meeting?src=hash&amp;ref_src=twsrc%5Etfw">
      #meeting
    </a> with <a href="https://twitter.com/hashtag/JohnSmith?src=hash&amp;ref_src=twsrc%5Etfw">
      #JohnSmith
    </a> about <a href="https://twitter.com/hashtag/defi?src=hash&amp;ref_src=twsrc%5Etfw">
      #defi
    </a>
    <br />
    <br />
    You get:
    <br />- a page with all meetings (<a href="https://twitter.com/hashtag/meeting?src=hash&amp;ref_src=twsrc%5Etfw">
      #meeting
    </a>)<br />- a page with all mentions of a person (<a href="https://twitter.com/hashtag/JohnSmith?src=hash&amp;ref_src=twsrc%5Etfw">
      #JohnSmith
    </a>)<br />- a page with all mentions of a concept (<a href="https://twitter.com/hashtag/defi?src=hash&amp;ref_src=twsrc%5Etfw">
      #defi
    </a>)<br />
    <br />
    It indexes your text.
  </p>
  &mdash; balajis.com (@balajis)
  <a href="https://twitter.com/balajis/status/1307318284010758145?ref_src=twsrc%5Etfw">
    September 19, 2020
  </a>
    </blockquote>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    3 tips for better writing:
    <br />
    <br />
    1) Double the examples.
    <br />
    1) Half the words.
    <br />
    1) One big idea.
  </p>
  &mdash; James Clear (@JamesClear)
  <a href="https://twitter.com/JamesClear/status/1305950332657205254?ref_src=twsrc%5Etfw">
    September 15, 2020
  </a>
    </blockquote>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    🗃️ Do you use Anki along with Obsidian? 🗃️
    <br />
    <br />
    Try this great script to add Anki cards directly from Markdown files by <a href="https://twitter.com/pseudonium?ref_src=twsrc%5Etfw">
      @pseudonium
    </a> :<a href="https://t.co/BzlrFPsIWl">https://t.co/BzlrFPsIWl</a>
  </p>
  &mdash; Obsidian.md (@obsdmd) <a href="https://twitter.com/obsdmd/status/1307415059547684864?ref_src=twsrc%5Etfw">September 19, 2020</a>
    </blockquote>
    <h1>{`An Article`}</h1>
    <p><a parentName="p" {...{
        "href": "https://roamingwriting.substack.com/p/what-is-roam-research"
      }}>{`What is Roam Research?`}</a></p>
    <p>{`It is an interesting take on the question "What is Roam Research?"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      